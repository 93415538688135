.chart-container {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    pointer-events: none; /* Prevent interaction when hidden */
}

.chart-container.show {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
}

.chart-container.hide {
    opacity: 0;
    transform: translateY(20px);
    pointer-events: none;
}

.hover-divider:hover {
    cursor: pointer; /* Makes it look clickable */
    color: #1890ff; /* Optional: Change text color on hover */
    text-decoration: underline; /* Optional: Add underline */
}