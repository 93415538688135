.pdf-upload-preview .ant-upload-list-item-container {
  width: 100% !important;
  height: 250px !important; }

.pdf-upload-preview .ant-upload-list-picture-card-container {
  width: auto !important;
  height: auto !important; }

.pdf-upload-preview .ant-upload-list-item-done {
  width: 100% !important;
  height: 250px !important; }

.pdf-preview-modal .ant-modal-confirm-body {
  justify-content: center !important; }
